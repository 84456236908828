html {
  box-sizing: border-box;
}

body {
  font-family: $standard-font-family;
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.15;
  margin: 0;
  color: $grey;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight: 700;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.clear{
  clear: both;
}

.page-wrap {
  position: relative;
  overflow: hidden;
  .page-inner-wrap {
    margin: 0 auto;
    position: relative;
    right: 0;
    z-index: 100;
    transition: right 0.3s ease;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    padding: $pagepadding;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.20);
    &.push {
      right: 430px;
    }
  }
}

.closeicon{
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 18px;
  height: 18px;
  background-size: 18px;
  opacity: .7;
  z-index: 2000;
  background-image: url(/img/close.png);
}
