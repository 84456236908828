$title-font-family:  "adelle-sans", sans-serif;
$standard-font-family: "adelle-sans", sans-serif;

$container: 1200;

$bold: 700 ;
$regular: 400 ;
$link: #4A90E2;

$grey: #444444;
$lightgrey: #A1A1A1;
$brightgreen: #6DBB42;
$lightgreen: #8BC024;
$linkblue: #4A90E2;

$pagepadding:50px;
