header{
  ul{
    margin-bottom: 40px;
    li{
      a{
        font-size: 14px;
        color: $grey;
        font-weight: $bold;
        padding-bottom: 8px;
        margin-right: 40px;
        background-repeat: no-repeat;
        padding-left: 36px;
        background-size: 25px;
        &.map{
          background-image: url(/img/map.png);
        }
        &.graph{
          background-image: url(/img/chart-scatter.png);
        }
        &:hover{
          border-bottom: 2px solid #cde3ec;
          color: $grey;
          text-decoration: none;
        }
        &.active{
          border-bottom: 2px solid #1EAEEE;
          &:hover{
            border-bottom: 2px solid #1EAEEE;
          }
        }
      }
    }
  }
}
