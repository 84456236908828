.backlink{
  a{
  font-size: 12px;
  font-weight: $regular;
  color: $linkblue;
  background-repeat: no-repeat;
  padding-left: 12px;
  background-size: 6px;
  background-position: center left;
  background-image: url(/img/angle-left.png);
  cursor: pointer;
  }
}
.graphdetailspage{
  display: flex;
  justify-content: space-between;
  height: 100vh;
  .halfpage{
    width: 48%;
    &:nth-child(2) {
      display: flex;
      justify-content: center;
      align-items:center;
      flex-direction: column;
    }
  }
}


.smallgraph {
  width: 100%;
  min-height: calc(100vh - 190px);
  display:flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  .grapharea {
    width: 100%;
    .graph{
      &.embassy{
        .square{
          min-height: 101px;
        }
      }


      .graphbottom{
        .worstbest{
          margin-top: 10px;
          .worst{
            width: 220px;
            text-align: left;
          }
          .best{
            width: 280px;
            text-align: right;
          }
          hr{
            margin: 7px 10px;
            border: 0;
            width: calc(100% - 50px);
            border-top: 1px solid #444444;
          }
        }
      }

    }
  }
  h2{
    font-size: 15px;
    text-align: left;
    width: 100%;
    padding-left: 65px;
    margin-bottom: 30px;
    span{
      font-weight: $regular;
    }
  }
}

.countrydetaillist{
  display: flex;
  flex-direction: row;
  padding-left: 15%;
  width: 100%;
  .countrycols{
    width: 30%;
    .areadetail{
      width: 100%;
      border-left: 2px solid #444444;
      padding: 8px 0 8px 8px;
      margin-bottom: 20px;
      h3{
        font-size: 18px;
        margin-bottom: 10px;
      }
      ul{
        list-style: none;
        li{
          width: 100%;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
