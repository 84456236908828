.mappage{
  background: #F5F5F5;
  .page-inner-wrap{
    padding: 0;
  }
  .innerpadding{
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .intro{
    padding-bottom: 20px;
    p{
      font-size: 12px;
      max-width: 230px;
    }
  }
}

.map{
  width: 100%;
  position: relative;
  .largemap {
    display: block;
    margin: 0 auto;
    width: 77%;
  }
  .smallcircle {
    position: absolute;
    &.indonesia {
      bottom: 42%;
      height: 54px;
      right: 24%;
      width: 200px;
    }
    &.egypt {
      height: 48px;
      right: 46%;
      top: 30%;
      width: 34px;
    }
    &.india {
      height: 140px;
      right: 34%;
      top: 29%;
      width: 83px;
    }
    &.jamaica {
      bottom: 60.5%;
      height: 14px;
      left: 29%;
      width: 30px;
    }
    &.nigeria {
      height: 54px;
      right: 50%;
      top: 41%;
      width: 61px;
    }
    &.russia {
      height: 134px;
      right: 20%;
      top: 4%;
      width: 581px;
    }
    &.qatar {
      height: 14px;
      right: 41.8%;
      top: 33%;
      width: 10px;
    }
    &.saudiarabia {
      height: 62px;
      right: 42.3%;
      top: 31%;
      width: 55px;
    }
    &.unitedarabemirates {
      height: 11px;
      right: 40.8%;
      top: 33.8%;
      width: 21px;
    }
    &.kenya {
      height: 41px;
      right: 43.8%;
      top: 46.8%;
      width: 34px;
    }
    &.morocco {
      height: 32px;
      right: 53.6%;
      top: 26.8%;
      width: 31px;
    }
    &.israel {
      height: 14px;
      right: 45.6%;
      top: 29.8%;
      width: 8px;
    }
    &.turkey {
      height: 17px;
      right: 44%;
      top: 23.8%;
      width: 67px;
    }
  }
  [data-tooltip]:before {
    position: absolute;
    bottom: 90%;
  }
  [data-tooltip]:after {
    position: absolute;
    bottom: 90%;
  }
  .btmimg {
    background-color: white;
    border-radius: 5px;
    bottom: 10px;
    box-shadow: 0 0 15px rgba(0,0,0,0.5);
    left: 35%;
    position: absolute;
    width: 34%;
    padding: 10px;
  }
}
