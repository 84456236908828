
.filterarea{
  display: flex;
  justify-content: space-between;
  margin: 50px 0 30px;
  padding-left: 84px;
  min-height: 25px;
  .quadrants{
    display: flex;
    h3{
      color: $grey;
      font-size: 18px;
    }
    input{
      width: 20px;
      margin: 3px 10px;
      padding: 0;
    }
    .quadrantoptions{
      display: flex;
      padding-left: 34px;
      padding-top: 4px;
      .option{
        display: flex;
        float: left;
        cursor: pointer;
        .boxs{
          margin: 0 10px 0 0;
          height: 15px;
          width: 15px;
          border-radius: 2px;
          float: left;

          &.pink{
            background: #F0B3BA;
          }
          &.yellow{
            background: #FCE4BD;
          }
          &.green{
            background: #D8F1BC;
          }
        }
        h4{
          font-size: 12px;
          font-weight: $regular;
          margin-right: 24px;
          margin-top: 2px;
          color: $grey;
        }
      }
    }


  }
  .selectbox{
    margin-top: 4px;
    h4{
      font-size: 12px;
      font-weight: $regular;
      margin-right: 10px;
      margin-top: 2px;
      float: left;
    }
    select{
      font-size: 12px;
      font-weight: $bold;
      border: none;
      width: 102px;
      float: left;
    }
  }
}
