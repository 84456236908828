
.grapharea{
  display: flex;
  .graphtitle{
    flex: 0 0 15px;
    min-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 70px;
    h2{
      transform: rotate(-90deg);
      white-space: nowrap;
      color: $grey;
      font-size: 20px;
    }
  }
  .graph{
    display: flex;
    width: 100%;
    flex-direction: column;
    .graphrow{
      min-width: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex: 0 0 15px;
      .title{
        transform: rotate(-90deg);
        white-space: nowrap;
        text-align: center;
        font-size: 12px;
        width: 70px;
        h3{

        }
        h4{
          font-weight: $regular;

        }
      }
    }
    .graphsquares{
      display: flex;
      flex-direction: row;
      margin: 0 -8px 0 -8px;
      width: 100%;
      .square{
        border: 2px solid #E7E7E7;
        background: #fff;
        border-radius: 4px;
        width: 100%;
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 16px;
        padding: 0;
        min-height: 131px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: background-color 0.5s ease;
        transition: border-color 0.5s ease;
        z-index: 1;
        h3{
          font-size: 12px;
          color: #9B9B9B;
          background: #E7E7E7;
          padding: 6px 10px;
          width: 100%;
          transition: background-color 0.5s ease;
          transition: color 0.5s ease;
        }
        &.showcolor{
          h3, .square{
            pointer-events: none;
          }
          &.orangefade{
            background: #FCE4BD;
            border: 2px solid #EBD3AC;
            h3{
              background: #EBD3AC;
              color: #B69E77 ;
            }
          }
          &.pinkfade{
            background: #F0B3BA;
            border: 2px solid #DFA2A9;
            h3{
              background: #DFA2A9;
              color: #AA6D74 ;
            }
          }
          &.greenfade{
            background: #D8F1BC;
            border: 2px solid #C7E0AB;
            h3{
              background: #C7E0AB;
              color: #92AB76 ;
            }
          }
          &.hover{
            opacity: .5;
            &.nohover{
              opacity: 1;
            }
          }
        }
        &:hover{
          cursor: pointer;
          border: 2px solid #C6C6C6;
          h3{
            background: #C6C6C6;
          }
        }
        &.jibblets{
          pointer-events: none;
          h3{

          }
          .square{

          }
          &.showcolor{
            &.hover{
              opacity: 1;
            }
          }
        }
        .inner-square{
          padding: 10px;
          display: flex;
          flex-wrap: wrap-reverse;
          margin: 0 -4px 0 -4px;
          z-index: 100;
          position: relative;
          @media screen and (max-width: 1500px){
            margin: 0 -2px 0 -2px;
          }
          .smallsquare{
            background: $lightgreen;
            width: calc(20% - 8px);
            display: block;
            height: 10px;
            border-radius: 2px;
            padding: 0;
            padding-top: 12%;
            margin: 0;
            height: 0;
            margin-left: 4px;
            margin-right: 4px;
            margin-top: 8px;
            margin-bottom: 0;
            cursor: pointer;
            @media screen and (max-width: 1500px){
              width: calc(20% - 4px);
              margin-left: 2px;
              margin-right: 2px;
              margin-top: 4px;
              padding-top: 14%;
            }
          }
        }
      }
      .hoverarea{
        display: flex;
        &.tl, &.br{
          width: 55.555%;
        }
        &.tr, &.bl{
          width: 44.444%;
        }
        &.active{
          &.hover{
            .square{
              opacity: .5;

            }
          }
          &.norollover{
            &.hover{
              .square{
                opacity: 1;
              }
            }

          }
        }

      }
    }
    .graphbottom{
      width: 100%;
      text-align: center;
      padding-left: 70px;
      .axis{
        display: flex;
        flex-direction: row;
        margin-left: -8px;
        margin-right: -8px;
        margin-bottom: 10px;
        .num{
          width: 100%;
          margin-left: 4px;
          margin-right: 4px;
          font-size: 12px;
        }
      }

      h2{
        color: $grey;
        font-size: 20px;
        margin-top: 20px;
      }
      .worstbest{
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-weight: $bold;
        display: flex;
        width: 100%;
        justify-content: space-between;
        span{
        width: 9.7%;

        }
        .worst{
          float: left
        }
        .best{
          float: right;
        }
      }
    }
  }
}

/**
 * Tooltip Styles
 */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 100;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 100;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -50px;
  padding: 7px;
  width: 100px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  z-index: 100;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
  z-index: 100;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
