form {
  width: 100%;
}
label {
  font-size: 18px;
  color: #99bf8c;
  letter-spacing: 0;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 10px;
  display: block;
}

input,
textarea {
  width: 100%;
  border-radius: 0;
  margin: 0;
  border: none;
  font-size: 16px;
  padding: 9px 25px;
  letter-spacing: 0;
  line-height: 1.35;
  border: 0;
  background: #ebebeb;
  border-radius: 0;
  color: #3d3d3d;
  margin-bottom: 20px;
  &.required {
    border: 1px solid #8b0000;
  }

  &:placeholder {
    color: #7d7d7d;
  }
  &.form-control {
    border-radius: 0;
  }
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7d7d7d;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #7d7d7d;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #7d7d7d;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #7d7d7d;
}
textarea {
  min-height: 97px;
  margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  -webkit-appearance: none;
}

button {
  margin-top: 10px;
  width: auto;
  color: white;
  background: #99bf8c;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 35px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  text-transform: uppercase;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  .form-group-col {
    width: calc(50% - 15px);
    &:nth-child(1) {
      margin-right: 30px;
    }
  }
  @media (max-width: 1150px) {
    flex-direction: row;
    .form-group-col {
      width: 100%;
      &:nth-child(1) {
        margin-right: 0;
      }
    }
  }
}

.validation-msg {
  background-color: #deeec9;
  padding: 20px 25px;
}
