#mobile-nav-items {
  background-color: white;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  max-width: 430px;
  width: 100vw;
  bottom: 0;
  height: 100%;
  z-index: 50;
  text-align: left;
  height: 100vh;
  overflow: auto;

  padding: 0;
  #mobile-nav-inner-items{


  }

}
.sidelink{
    cursor:pointer;
}
#details{
    width: 100%;
    clear:both;
    float: left;
}



.sidebardetail{
  background: #fff;
  padding: 50px 50px 70px 50px;

  scroll-behavior: smooth; /* <--- */

  .title{
    margin-top: 7px;
    margin-bottom: 40px;
    .greenbox{
      margin: 13px 10px 12px 0;
      height: 15px;
      width: 15px;
      border-radius: 2px;
      background: #6DBB42;
      float: left;
      color:$brightgreen;
    }
    h2{
      font-size: 34px;
      display: inline-block;
      margin-bottom: 10px;
      width: 200px;
    }
    .embassy {
      background: #FCE4BD;
      border-radius: 4px;
      float: right;
      font-size: 14px;
      font-weight: $bold;
      padding: 8px 18px;
      margin-top: 4px;
      &.rome {
        background-color: #F0B3BA;
        color: #FEFEFE;
      }
      &.advocate {
        background-color: #D8F1BC;
      }
    }
    h3{
      width: 100%;
      color: $lightgrey;
      font-size: 18px;
      clear: both;
      span{
        font-weight: $regular;
      }
    }
    img{
      height: 21px;
      margin-top: 12px;
    }
    &.titleborder{
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 10px;
      margin-bottom: 40px;
    }
  }
  .overview{
    margin-bottom: 50px;
    .assessment{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin: 12px -2px 12px -2px;
      width: 100%;
      .progress{
        height: 5px;
        margin-left: 2px;
        margin-right: 2px;
        margin-bottom: 0;
        background: #EDEDED;
        border-radius: 10px;
        display: block;
      }
      &.ilga{
        .progress{
          width: calc(11.111% - 4px) ;
          &.active{
            background: #F5A623;
          }
        }
        &.beige {
          .progress.active { background: #e6cfb1; }
        }
        &.deepred {
          .progress.active { background: #cc3226; }
        }
        &.yella {
          .progress.active { background: #ffe093; }
        }
      }
      &.openness{
        .progress{
          width: calc(25% - 4px) ;
        }
        &.one .progress.active { background: #D0021B; }
        &.two .progress.active { background: #2CB1BD; }
        &.three .progress.active { background: #F5A623; }
        &.four .progress.active { background: #8BC024; }
      }
    }
  }

  .links{
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 20px;
    margin-bottom: 30px;
    h3{
      margin-bottom: 20px
    }
    a{
      color:$linkblue;
      text-decoration: underline;
      font-size: 12px;
      margin:0 0 15px 0;
      display: block;
      width: 100%;
      font-weight: $bold;
    }
    &.sublinks{
      padding-bottom: 0;
      padding-top: 30px;
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  .furtherinfo{
    h3{
      float: left;
      width: fit-content;

    }
    .linkright{
      font-size: 14px;
      font-weight: $bold;
      float: right;
    }

    p{
      clear: both;
      .sidebarlink{
        float: none;
        padding-left: 0;
        font-size: 12px;
        background-size: none;
        background-position: center left;
        background-image: none;
        font-weight: $regular;

      }
    }

    img{
      width: 100%;
      margin-top: 10px;
    }
  }

  .points{
    margin-top: 50px;
    .number{
      font-size: 18px;
      font-weight:$bold;
      margin-bottom: 10px;
    }
    h3{
      font-size: 12px;
      line-height: 20px;
      a{
        width: 100%;
        display: block;
      }
      span{
        width: 100%;
        font-weight: $regular;
      }
    }
    h4{
      font-size: 12px;
      font-weight: $regular;
    }
    h5 {
      font-size: 12px;
    }
    p{
      font-size: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      a{

      }
    }
    .furtherinfo{
      margin-top: 40px;
      h4{
        font-size: 12px;
        line-height: 20px;
        a{

        }
      }
    }
  }

  .generaltext{
    padding-top: 40px;
    ul{
      list-style: none;
      font-size: 10px;
      margin-bottom: 60px;
      li{
        background-repeat: no-repeat;
        background-image: url(/img/bullet.png);
        padding-left: 36px;
        background-position: 8px 8px;
        p{
          font-size: 10px;
          line-height: 16px;
          b {
            font-weight: $bold;
          }
        }
      }
    }
  }

  .countrylist{
    h2{
      font-size: 34px;
      margin-bottom: 40px;
    }
    ul{
      list-style: none;
      li{

        border-bottom: 1px solid #9B9B9B;
        width: 100%;
        display: flex;
        padding: 13px 0;
        .greenbox{
          margin: 0px 15px 0px 0;
          height: 15px;
          width: 15px;
          border-radius: 2px;
          background: #6DBB42;
          float: left;
          color:$brightgreen;

        }
        h4{
          font-size: 14px;
          font-weight: $bold;
          float: left;

        }
      }
    }
  }



  .nextprev{
    border-top: 1px solid #D8D8D8;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding-top: 20px;
    a{
        font-size: 12px;
        font-weight: bold;
        color: $linkblue;
        background-repeat: no-repeat;
        background-size: 6px;
        background-image: url(/img/angle-left.png);
        cursor: pointer;
    }
    p{
      font-size: 10px;
      color: #9B9B9B;
      padding-top: 6px;


    }
    .left{
      width: 120px;
      text-align: left;
      a{
          padding-left: 13px;
          background-position: center left;
          background-image: url(/img/angle-left.png);
      }
      p{
        padding-left: 12px;
      }
    }
    .right{
      width: 120px;
      text-align: right;
      a{
          padding-right: 13px;
          background-position: center right;
          background-image: url(/img/arrow_right.png);
      }
      p{
        padding-right: 12px;
      }
    }
    .inactive{
      opacity: .2;
    }
  }

  .standardtitle{
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%;
  }
  .standardtitlesub{
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: $regular;
    width: 100%;
  }
  .smallbtmmargin{
    margin-bottom: 2px;
  }
  .largetitle{
    font-size: 18px;
  }
  p{
    font-size: 12px;
    font-weight: $regular;
    margin-bottom: 20px;
    line-height: 16px;
    a{
      color: $linkblue;
    }
  }
}
